<template>
      <v-row>
        <v-col cols="12" md="6" class="bg-img">
          <div class="text-info black--text">
            <h1 class="text-center">Dashboard</h1>
            <p>iob website is a collection of market data, information and trading tools.</p>
          </div>
        </v-col>
        <v-col cols="12" md="6">
          <v-btn v-if="env == 'Development'" rounded dark color="primary white--text" class="right text-none mr-3 mt-3" target="_black" href="http://127.0.0.1:8080">ioBots</v-btn>
          <v-btn v-if="env == 'Test'" rounded dark color="primary white--text" class="right text-none mr-3 mt-3" target="_black" href="https://testnet.iobots.pro">ioBots</v-btn>
          <v-btn v-if="env == ''" rounded dark color="primary white--text" class="right text-none mr-3 mt-3" target="_black" href="https://iobots.pro">ioBots</v-btn>
          <v-card tile class="elevation-3 center-auto" max-width="380px">
            <div class="text-center">
              <div class="fs-24 fw-700 pt-5">ioBots-Dashboard</div>
              <div class="fs-16 pt-5">Sign into your account</div>
            </div>
            <v-card-text class="pb-0">
              <v-form ref="userForm" v-model="userFormValid" class="mb-0 pb-2">
                <v-text-field prepend-icon="fas fa-user" v-model="userForm.username" :rules="usernameRules" class="pb-2" placeholder="Username" required @keyup.enter="signIn"></v-text-field>
                <v-text-field prepend-icon="fas fa-lock" v-model="userForm.password" :rules="passwordRules" class="pb-2" placeholder="Password" type="password" required @keyup.enter="signIn"></v-text-field>
                <v-text-field prepend-icon="fas fa-shield-alt" v-model="userForm.otp" placeholder="Two Factor Authentication (If enabled)" required @keyup.enter="signIn"></v-text-field>
              </v-form>
            </v-card-text>
            <v-card-actions class=" px-5">
                <v-btn block tile :disabled="!userFormValid" class="primary mb-5" @click="signIn" :loading="signInLoading">Sign In</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
</template>

<script>
  import Vue from 'vue';
  import config from '../../config';
  import { mapGetters } from "vuex";
  export default {
    data() {
      return {
        userFormValid: true,
        signInLoading: false,
        env: config.environment,
        userForm: {
          username: null,
          password: null,
          otp:null,
        },
        usernameRules: [
          v => !!v || 'Username is required'
        ],
        passwordRules: [
          v => !!v || 'Password is required'
        ],
      }
    },
    computed: {
      ...mapGetters(['user'])
    },
    created() {
      if (this.user.token) {
        this.$router.push({ name: 'home' });
      }
    },
    mounted() {
      this.userForm.username = localStorage.getItem("iobots-admin-username");
      this.userForm.password = localStorage.getItem("iobots-admin-password");
    },
    methods: {
      signIn() {
        if (this.$refs.userForm.validate()) {
          this.signInLoading = true;
          localStorage.setItem('iobots-admin-username', this.userForm.username);
          localStorage.setItem('iobots-admin-password', this.userForm.password);
          let api = config.baseUrl + '/user/public/adminLogin';
          let sha256 = require("js-sha256").sha256;
          let password = sha256(this.userForm.password);
          let param = {
            username: this.userForm.username,
            password: password,
            otp:this.userForm.otp
          };
          this.$http.post(api, param).then(response => {
            let data = response.data;
              this.signInLoading = false;
            if (data.code == 200) {
              // 登录成功，存储用户信息
              this.$store.dispatch('userHandler', data.result);
              localStorage.setItem("iobots-admin-token", data.result.token);
              // localStorage.setItem("iobots-admin-expires", (new Date()).getTime() + 1000 * 30 )
              localStorage.setItem("iobots-admin-expires", (new Date()).getTime() + 1000 * 60 * 60 * 2);
              this.signInLoading = false;
              // localStorage.setItem("iobots-admin-image", data.result.avatar);
              this.$router.push({ name: "haStatsSummary" });
              this.$router.go();
              // window.location.href = config.domainName;
            } else {
              // alert(data.message);
              this.$store.dispatch('snackbarMessageHandler', data.message);
            }
          }, error => {
            alert('Error');
            this.$store.dispatch('snackbarMessageHandler', 'Error');
              this.signInLoading = false;
          });
        } else {
          alert('Username and password are required.');
          this.$store.dispatch('snackbarMessageHandler', 'Username and password are required.');
        }
      }
    },
  }
</script>
<style>
.bg-img {
    background: rgba(0, 0, 0, 0.04) url(../../../static/img/bg.jpg) top left repeat;
        background-size: auto;
    background-size: cover;
    top: 0;
    width: 100%;
    bottom: 0;
    opacity: 1;
    z-index: 999;
    min-height: 100vh;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px;
}
.text-info {
    max-width: 350px;
    margin: 0 auto;
    padding: 30px 20px;
    border: solid 5px #fff;
}
</style>